export const environment = {
  production: false,
  stage: 'integration',
  companyId: 'krc4PsvgVuBKYck4mxen',
  firebaseConfig: {
    apiKey: 'AIzaSyC08Ki2u-bSDdv4pUaVVO7WMZswi4tB57s',
    authDomain: 'eule-int.firebaseapp.com',
    projectId: 'eule-int',
    storageBucket: 'eule-int.appspot.com',
    messagingSenderId: '845792988314',
    appId: '1:845792988314:web:701418134fa8298b996323',
    measurementId: 'G-M74789VV2F',
    reCaptchaEnterpriseKey: '6Lf4d9wqAAAAAJKh1lJuFMm0NqsCy3LZJUwMf3es',
  },
};
